import React from 'react'
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";

import { fadeIn } from "../../utils/motion";
const SkillsCard = ({
    index,
    containerClassName,
    stack,
    icon,
    skillName,
  }) => {
    return (
      <Tilt className={`skill-unit ${containerClassName}`}>
        <motion.div variants={fadeIn("right", "spring", 0.5 * index, 0.75)}>
          <div
            options={{
              max: 45,
              scale: 0.8,
              speed: 450,
            }}
          >
            <p className="text">{stack}</p>
            <div className="skills-icon-wrapper">{icon}</div>
            <p className="skill-name">{skillName}</p>
          </div>
        </motion.div>
      </Tilt>
    );
  };

export default SkillsCard