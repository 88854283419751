import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { BsHouseDoor } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { UilListUl } from "@iconscout/react-unicons";
import { UilApps } from "@iconscout/react-unicons";
import { UilChat } from "@iconscout/react-unicons";
import { Link, animateScroll as scroll } from "react-scroll";

const navLinks = [
  {
    to: "home",
    offset: 0,
    IconComponent: BsHouseDoor,
  },
  {
    to: "about",
    offset: 0,
    IconComponent: FiUser,
  },
  {
    to: "skills",
    offset: 0,
    IconComponent: UilListUl,
  },
  {
    to: "projects",
    offset: 0,
    IconComponent: UilApps,
  },
  {
    to: "contact",
    offset: 0,
    IconComponent: UilChat,
  },
];

function Navbar() {


  return (
    <div className="navbar">
      <ul className="navbar-list">
        {navLinks.map((link, index) => {
          const { to, offset, IconComponent } = link;
          return (
            <li key={index}>
              <Link
                to={to}
                spy={true}
                smooth={true}
                duration={500}
                className="nav-item"
                activeClass="nav-item-active"
                offset={offset}
              >
                <IconComponent className="navbar-icon" />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Navbar;

