import React, { useEffect } from "react";
import "./Projects.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { projectsData } from "./ProjectsData";
import ProjectItem from "./ProjectItem";

const Projects = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  return (
    <div className="projects" id="projects">
     
      {projectsData.map((project) => (
        <ProjectItem project={project} key={project.id} />
      ))}
    </div>
  );
};

export default Projects;

