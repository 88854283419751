import Navbar from "./components/Navbar/Navbar";
import Skills from "./sections/Skills/Skills";
import Projects from "./sections/Projects/Projects";
import Contact from "./sections/Contact/Contact";
import SuperHome from "./sections/Home/SuperHome";
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (

    <BrowserRouter>
      <div className="App">
        <Navbar />
        <SuperHome />
        <Skills />
        <Projects />
        <Contact />
      </div>
      
    </BrowserRouter>
  );
}

export default App;
