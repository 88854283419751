import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link, animateScroll as scroll } from "react-scroll";
import Ivana_Cliffords_Resume from "../../assets/Ivana_Cliffords_Resume.pdf";
import { BsFillBookmarkFill } from "react-icons/bs";
import { Controller, Scene } from "react-scrollmagic";
import "./SuperHome.css";
import CustomLink from "../../components/Link/CustomLink";

const AosComponent = ({ children, ...aosProps }) => {
  return (
    <div data-aos="fade-zoom-in" data-aos-once="true" {...aosProps}>
      {children}
    </div>
  );
};

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  return (
    <section className="super-home">
      <Controller>
        <Scene triggerHook="onLeave" duration={"300%"} pin>
          <img
            className="home-img"
            src={`${process.env.PUBLIC_URL}/images/hero-img.jpeg`}
            alt="Colorful artistic face"
          />
        </Scene>
        <div className="scrolling-pages">
          <div className="home-content" id="home">
            <div className="home-intro">
              <img
                className="home-img-mobile"
                src={`${process.env.PUBLIC_URL}/images/hero-img-mobile.jpeg`}
                alt="Colorful artistic face"
              />
              <AosComponent
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-delay="200"
              >
                <p className="home-h1">Hello world.</p>
              </AosComponent>
              <AosComponent
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-delay="500"
              >
                <h1 className="home-h1">
                  I'm <span className="ivana">Ivana Cliffords</span>
                </h1>
              </AosComponent>
              <AosComponent
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-delay="800"
              >
                <p className="home-par-2">A full stack web developer</p>
              </AosComponent>
            </div>
            <div
              className="home-contact"
              style={{ marginTop: "20px", gap: "10px" }}
            >
              <AosComponent
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-delay="1000"
              >
                <CustomLink
                  url="#contact"
                  className=" cta"
                  text="contact"
                  svgClassName="cta-svg-special"
                  spanClassName="dark-span"
                />
              </AosComponent>

              <AosComponent
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-delay="1100"
              >
                <CustomLink
                  url={Ivana_Cliffords_Resume}
                  className="cta"
                  text="resume"
                  svgClassName="cta-svg-special"
                  spanClassName="dark-span"
                  target="_blank"
                />
              </AosComponent>
            </div>
          </div>

          <div className="about-content" id="about">
            <AosComponent data-aos-offset="200" data-aos-duration="600">
              <p className="about-par">
                I thrive in dynamic and fast paced environments because that’s
                when my organizational and logistical skills are utilized best.
                <span className="ivana">
                  {" "}
                  I'm focused on continued education and growth
                </span>{" "}
                and take great pride in expanding my role in the team's overall
                success.
              </p>
            </AosComponent>
            <br />
            <AosComponent data-aos-offset="200" data-aos-duration="600">
              <p className="about-par">
                I'm all about{" "}
                <span className="ivana">making things beautiful </span>and enjoy
                software development because I appreciate precision,
                cleanliness, and efficiency.
              </p>
            </AosComponent>
            <br />
          </div>

          <div className="about-education">
            <ul className="about-ul">
              {[
                {
                  text: (
                    <>
                      MIT xPRO{" "}
                      <strong>
                        Professional Certificate in Coding: Full Stack
                        Development with MERN
                      </strong>
                    </>
                  ),
                  icon: <BsFillBookmarkFill className="about-icon" />,
                },
                {
                  text: (
                    <>
                      University of Belgrade<strong> BS in Sociology</strong>
                    </>
                  ),
                  icon: <BsFillBookmarkFill className="about-icon" />,
                },
                {
                  text: (
                    <>
                      Lived in <strong>three</strong> and travelled to <strong>seventeen</strong>{" "}
                      countries
                    </>
                  ),
                  icon: <BsFillBookmarkFill className="about-icon" />,
                },
              ].map((item, index) => (
                <AosComponent
                  key={index}
                  data-aos-offset="200"
                  data-aos-duration="600"
                >
                  <li>
                    {item.icon} {item.text}
                  </li>
                </AosComponent>
              ))}
            </ul>
          </div>
        </div>
      </Controller>
    </section>
  );
};

export default Home;

