import React, { useState } from "react";
import "./Skills.css";
import { skillsData } from "./SkillsData";
import SkillsCard from "./SkillsCard";
import BottomDivider from "../../components/ShapeDividers/BottomDivider/BottomDivider";


const Skills = () => {
  const [skills, setSkills] = useState(skillsData);
  return (
    <section className="skills" id="skills">
      <BottomDivider />
      <div className="skills-wrapper">
        {skills.map((skill, index) => (
          <SkillsCard
            key={skill.id}
            index={index}
            skillName={skill.skillName}
            containerClassName={skill.containerClassName}
            stack={skill.stack}
            icon={skill.icon}
          />
        ))}
      </div>
    </section>
  );
};

export default Skills;

