import React from "react";
import { BsGithub } from "react-icons/bs";
import CustomLink from "../../components/Link/CustomLink";

const ProjectItem = ({ project }) => (
  <div
    className={`project-item ${project.className}`}
    data-aos="fade-zoom-in"
    data-aos-offset="200"
    data-aos-easing="ease-in"
    data-aos-duration="600"
    data-aos-delay={project.id * 100}
    data-aos-once="true"
  >
    <div className="project-content">
      <div className="project-text">
        <p className="project-role">{project.role}</p>
        <h2 className="project-title">{project.title}</h2>
        <div className="tech-wrapper" style={{ maxWidth: "330px", display: "flex", flexWrap: "wrap", gap: "4px", height:"fit-content" }}>
          {project.techStack.map((tech) => (
            <span className="project-tech">{tech}</span>
          ))}
        </div>
        <p className="project-desc">{project.description}</p>
      </div>
      <div className="project-links">
        <a href={project.githubLink} target="_blank" rel="noreferrer noopener">
          <BsGithub className="project-icon" />
        </a>

        <CustomLink
          url={project.websiteLink}
          className="projects-cta cta"
          text="Website"
          svgClassName="cta-svg"
          spanClassName="dark-span"
          target="_blank"
        />
      </div>
    </div>
    <div className="project-img-wrapper">
      <div
        style={{
          backgroundImage: `url(${project.image})`,
          backgroundPosition: "center",
          backgroundColor: "#fff",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "30px",
        }}
        className="project-img"
      ></div>
    </div>
  </div>
);

export default ProjectItem;
