export const projectsData = [
    {
        id: 1,
        role: "Frontend Developer Consultant",
        title: "ROI Calculator",
        techStack: ["HTML", "SCSS", "React", "MaterialUI", "ChartJS"],
        description:
            "I designed and developed a ROI tool using React JS, utilizing useContext and useReducer for state management. Material UI was used for form components and Chart JS for data visualization.",
        image: "/images/project-four.png",
        githubLink: "https://github.com/IvanaCliffords",
        websiteLink: "https://ivanacliffords.github.io/calc-roi/",
        className: "project-item-four"
    },
    {
        id: 2,
        role: "Junior developer",
        title: "FreeWater Website",
        techStack: ["Wordpress", "HTML", "CSS"],
        description:
            "I designed and created a frontend of a wordpress website. I added new functionalities and wrote custom CSS to create more personalized design. The website had 125,000 users in a month.",
        image: "/images/project-three.png",
        githubLink: "https://github.com/IvanaCliffords",
        websiteLink: "https://www.freewater.io/",
        className: "project-item-three"

    },
    {
        id: 3,
        role: "Junior Frontend developer",
        title: "Blog Application",
        techStack: ["HTML", "SCSS", "React", "Axios"],
        description:
            "The blog application frontend is built with React, SCSS, and Axios, providing an intuitive interface for filtering posts by category. With dynamic rendering, state and props management, and seamless communication with the backend API, users can easily access and view relevant blog content. The use of SCSS allows for flexible styling options, enhancing the visual presentation of the application.",
        image: "/images/blog-app-project.jpeg",
        githubLink: "https://github.com/IvanaCliffords",
        websiteLink: "https://coolhealth.com/providers/providers-news/",
        className: "project-item-four"

    },
    {
        id: 4,
        role: "Personal project",
        title: "E-commerce Store",
        techStack: [
            "React",
            "React Bootstrap",
            "CSS",
            "Axios",
            "NodeJS",
            "MongoDB",
            "Heroku",
        ],
        description:
            "E-commerce React and Node project. I used Express server, useReducer hook to maintain the state of the app, Axios to make the API calls, and React-Bootstrap to style the app. It has user authentication and PayPal setup. The website is deployed on Heroku, with possible latency issues due to its hosting environment.",
        image: "/images/project-one.jpeg",
        githubLink: "https://github.com/IvanaCliffords/e-commerce-website",
        websiteLink: "https://drippy-store.herokuapp.com",
        className: "project-item-one"

    },
    {
        id: 5,
        role: "Personal project",
        title: "Feeling Good App",
        techStack: [
            "React",
            "CSS",
            "MaterialUI",
            "Node",
            "ExpressJS",
            "MongoDB",
            "Heroku",
        ],
        description:
            "MERN app that uses Redux to maintain state. It portrays CRUD functionalities, since users can create posts, upload images, edit and delete the posts. It also makes request to Quotes API. The website is deployed on Heroku, with possible latency issues due to its hosting environment.",
        image: "/images/project-two.jpeg",
        githubLink: "https://github.com/IvanaCliffords/feel-good-app",
        websiteLink: "https://feel-good-app.herokuapp.com/",
        className: "project-item-two"

    }
]