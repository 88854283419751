import React from "react";
import "./CustomLink.css";

const CustomLink = ({ url, className, text, svgClassName, spanClassName, target, delay }) => {
  return (
    <a
      href={url}
      target={target}
      rel="noreferrer noopener"
      className={`${className}`}
      data-aos="fade-zoom-in"
      data-aos-offset="200"
      data-aos-easing="ease-in"
      data-aos-duration="600"
      data-aos-delay={delay}
      data-aos-once="true"
    >
      <span className={spanClassName}>{text}</span>
      <svg
        width="13px"
        height="10px"
        viewBox="0 0 13 10"
        className={`cta-svg-special ${svgClassName}`}
      >
        <path d="M1,5 L11,5"></path>
        <polyline points="8 1 12 5 8 9"></polyline>
      </svg>
    </a>
  );
};

export default CustomLink;
