import React, { useEffect, useRef, useState } from "react";
import "./Contact.css";
import Aos from "aos";
import "aos/dist/aos.css";
import CustomLink from "../../components/Link/CustomLink";
import { motion } from "framer-motion";
import { EarthCanvas } from "../../assets/canvas/index";
import { slideIn } from "../../utils/motion";

import TopDivider from "../../components/ShapeDividers/TopDivider/TopDivider";
function Contact() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <section className="contact" id="contact">
     <TopDivider />

      <motion.div
        variants={slideIn("right", "tween", 0.2, 1)}
        className="contact-img"
      >
        <EarthCanvas />
      </motion.div>

      <motion.div
        variants={slideIn("left", "tween", 0.2, 1)}
        className="contact-info-div"
      >
        <h2
          className="contact-h2"
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in"
          data-aos-duration="600"
          data-aos-delay="200"
          data-aos-once="true"
        >
          Let's talk
        </h2>
        <a
          href="mailto:ivana.cliffords@gmail.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <p
            className="contact-info-link"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in"
            data-aos-duration="600"
            data-aos-delay="400"
            data-aos-once="true"
          >
            ivana.cliffords@gmail.com
          </p>
        </a>

        <CustomLink
          url="https://calendly.com/ivana-cliffords/15min"
          className="cta"
          text="Get in touch"
          svgClassName="cta-svg-special-light"
          spanClassName="white-span"
          target="_blank"
        />
      </motion.div>
    </section>
  );
}

export default Contact;

